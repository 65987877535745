#logo {
  animation: fill 0.5s ease forwards 4.5s;
}
@media only screen and (max-width: 620px) {
  #logo {
    left: -2.7rem;
    transform: scale(0.8);
  }
}

#logo path:nth-child(1) {
  stroke-dasharray: 167.09px;
  stroke-dashoffset: 167.09px;
  animation: line-anim 1s ease forwards 2.4s;
}

#logo path:nth-child(2) {
  stroke-dasharray: 290px;
  stroke-dashoffset: 290px;
  animation: line-anim 1s ease forwards 2.6s;
}
#logo path:nth-child(3) {
  stroke-dasharray: 164px;
  stroke-dashoffset: 164px;
  animation: line-anim 1s ease forwards 2.8s;
}
#logo path:nth-child(4) {
  stroke-dasharray: 224px;
  stroke-dashoffset: 224px;
  animation: line-anim 1s ease forwards 2.9s;
}
#logo path:nth-child(5) {
  stroke-dasharray: 171px;
  stroke-dashoffset: 171px;
  animation: line-anim 1s ease forwards 2s;
}
#logo path:nth-child(6) {
  stroke-dasharray: 224px;
  stroke-dashoffset: 224px;
  animation: line-anim 1s ease forwards 2.1s;
}
#logo path:nth-child(7) {
  stroke-dasharray: 158.43px;
  stroke-dashoffset: 158.43px;
  animation: line-anim 1s ease forwards 2.2s;
}
#logo path:nth-child(8) {
  stroke-dasharray: 350px;
  stroke-dashoffset: 350px;
  animation: line-anim 1s ease forwards 2.3s;
}
#logo path:nth-child(9) {
  stroke-dasharray: 171px;
  stroke-dashoffset: 171px;
  animation: line-anim 1s ease forwards 2.5s;
}
#logo path:nth-child(10) {
  stroke-dasharray: 300px;
  stroke-dashoffset: 300px;
  animation: line-anim 1s ease forwards 2.9s;
}
#logo path:nth-child(11) {
  stroke-dasharray: 200px;
  stroke-dashoffset: 200px;
  animation: line-anim 1s ease forwards 3.4s;
}
#logo path:nth-child(12) {
  stroke-dasharray: 171px;
  stroke-dashoffset: 171px;
  animation: line-anim 1s ease forwards 3.6s;
}
#logo path:nth-child(13) {
  stroke-dasharray: 182px;
  stroke-dashoffset: 182px;
  animation: line-anim 1s ease forwards 3.7s;
}
#logo path:nth-child(14) {
  stroke-dasharray: 250px;
  stroke-dashoffset: 250px;
  animation: line-anim 1s ease forwards 3.8s;
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: #fff;
  }
}
