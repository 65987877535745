.About-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.About-page-title {
  font-family: "Merriweather", serif;
  margin: 18vh 0 0 0;
  animation: fade-in 0.8s ease-in forwards;
  color: var(--sub-color);
}
.About-photo-container {
  width: 80vw;
  padding: 5rem 0 15rem 0;
  background-image: url("../../Assets/megoofin.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 5vh;
  animation: slide-on-up 0.5s ease-in forwards;
}
.About-photo-container::after {
  z-index: -1;
  position: absolute;
  content: "";
  width: 75vw;
  height: 70vw;
  margin-left: 3rem;
  background-color: #78e3fd;
}

.About-page-details {
  margin-top: 10vh;
  padding: 5vh;
  background-color: #edbbb4;
  width: 90vw;
  font-family: "Merriweather", serif;
  line-height: 2em;
  color: #000;
}
.About-details-top {
  padding-bottom: 5vh;
}

@media only screen and (min-width: 768px) {
  .About-page-title {
    font-size: 3rem;
    font-family: "Merriweather", serif;
    margin: 10vh 0 0 0;
    animation: fade-in 0.8s ease-in forwards;
    color: var(--sub-color);
  }
  .About-photo-details-wrapper {
    margin-top: 5vh;
    max-width: 80vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    /* border: 2px solid; */
  }
  .About-page-details {
    margin-top: 0vh;
    padding: 5vh;
    background-color: #edbbb4;
    width: 55%;
    font-family: "Merriweather", serif;
    line-height: 2em;
    color: #000;
  }
  .About-photo-container {
    max-width: 20rem;
    max-height: 20rem;
    padding: 5rem 0 15rem 0;
    background-image: url("../../Assets/megoofin.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 0vh;
    animation: slide-on-up 0.5s ease-in forwards;
    /* border: 2px solid; */
  }
  .About-photo-container::after {
    z-index: -1;
    position: absolute;
    content: "";
    max-width: 20rem;
    max-height: 20rem;
    margin-left: 3rem;
    background-color: #78e3fd;
  }
}
