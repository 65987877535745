* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.HomePage-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #fff;
}
.App-header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 160px;
  background-color: var(--main-blue);
  animation: slide-up 1s ease-in-out forwards 1.5s;
}
.App-desktop-items-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App-tyler-name-wrapper {
  margin: 0;
}
.App-gif-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  transform: scale(0);
  margin-top: 15vh;
  animation: scale 1s ease-in-out forwards 0.2s;
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1.3);
  }
}
.App-home-icons {
  display: none;
}

@keyframes open {
  0% {
  }
  100% {
    opacity: 1;
  }
}

.App-details-box {
  opacity: 0;
  margin-top: 15vh;
  width: 90vw;
  animation: fade-in 0.5s ease-in-out forwards 2s;
}
@keyframes fade-in {
  0% {
  }
  100% {
    opacity: 1;
  }
}
.App-quick-about {
  text-align: center;
  line-height: 40px;
  color: #131515;
  font-family: "Merriweather", serif;
  font-size: calc(10px + 3vmin);
}
.App-links-wrapper {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  animation: fade-in 0.5s ease-in-out forwards 2s;
}

.App-footer-spacer {
  width: 100vw;
  height: 50px;
  padding-bottom: 200px;
}
.App-footer {
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 0;
  width: 100vw;
  background-color: var(--main-blue);
  height: 100px;
}
.App-contact-icons-footer {
  opacity: 0;
  animation: fade-in 0.5s ease-in-out forwards 2s;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70vw;
}

@media only screen and (min-width: 768px) {
  .App-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .App-desktop-items-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;
    margin-right: 3vw;
  }
  .App-tyler-name-wrapper {
    margin-top: 5vh;
  }
  .App-gif-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: static;
    transform: scale(0);
    margin-top: 11vh;
    animation: scale 1s ease-in-out forwards 0.2s;
  }
  .App-home-icons {
    display: flex;
    justify-content: space-between;
    margin-top: 5vh;
    opacity: 0;
    animation: open 0.9s ease-in forwards 0.5s;
    width: 20%;
  }
  .App-icons {
    transition: transform 0.3s;
  }
  .App-icons:hover {
    transform: scale(1.1);
  }

  .App-quick-about {
    line-height: 70px;
  }
  .App-links-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 70vw;
    margin-top: 5vh;
  }
  .App-footer {
    position: static;
    display: flex;
    justify-content: space-around;
    align-items: center;
    bottom: 0;
    width: 100vw;
    background-color: var(--main-blue);
    height: 100px;
  }
  .App-contact-icons-footer {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  @keyframes scale {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}
