.resume-container {
  margin-top: 15vh;
  display: flex;
  justify-content: space-between;
}

.myresume {
  width: 50vw;
  height: 100vh;
}

@media screen and (max-width: 425px) {
  .myresume {
    width: 100vw;
  }
}
