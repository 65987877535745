.project-body-wrapper {
  width: 90vw;
  margin-top: 50px;
}

.project-header {
  text-align: center;
  background-color: #edbbb4;
  padding: 2vh 0 2vh 0;
}
.project-header a {
  color: #4f759b;
  font-size: 1.2rem;
  font-family: "Merriweather", serif;
  transition: font-size 0.3s;
  text-decoration: underline;
}
.project-header a:hover {
  color: #c7edf7;
  font-size: 1.23rem;
}

.project-picture {
  border: 3px solid #edbbb4;
  max-width: 90vw;
  height: 300px;
  background-size: 80%;
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-color: #fff;
}
.project-details {
  text-align: center;
  background-color: #edbbb4;
  padding: 1vh 1vw 0vh 1vw;
  font-family: "Merriweather", serif;
  font-size: 14px;
}
.Project-icon-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #edbbb4;
}

@media only screen and (min-width: 768px) {
  .project-body-wrapper {
    width: 50vw;
    margin-top: 50px;
  }
  .project-picture {
    border: 3px solid #edbbb4;
    max-width: 90vw;
    height: 300px;
    background-size: 35%;
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-color: #fff;
  }
  .icon-link {
    transition: transform 0.3s;
  }
  .icon-link:hover {
    transform: scale(1.1);
  }
}
