.Button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}
.Button-name {
  font-size: 2rem;
  font-family: "Merriweather", serif;
  color: var(--sub-color);
  /* border: 1px solid black; */
  margin: 0;
  padding: 0;
}
.Button-image {
  max-width: 100%;
  max-height: 100%;
  /* border: 1px solid black; */
}

@media only screen and (min-width: 768px) {
  .Button-wrapper {
    width: 20vw;
  }
  .Button-name {
    transition: transform 0.3s;
  }
  .Button-name:hover {
    transform: scale(1.1);
  }
  .Button-image {
    transition: transform 0.3s;
  }
  .Button-image:hover {
    transform: scale(1.1);
  }
}
